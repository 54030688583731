<template>
  <div class="scientificDetail">
      <div class="banner">
        <div class="container">
            <p>科研诚信动态</p>
        </div>
      </div>

    <div class="content">
        <div class="container">
            <a-row>
                <!-- <a-col class="contentL" :md="24" :lg="18" :xl="18"> -->
                <a-col class="contentL" :md="24" :lg="24" :xl="24">
                    <div class="contentLBox">
                        <div>
                            <span class="back" @click="back">
                                <i class="el-icon-arrow-left"></i> 返回
                            </span>
                        </div>
                        <div class="contentText">
                            <div class="title">{{newsDetail.noticeTitle}}</div>
                            <p class="date">{{newsDetail.createTimeText}}</p>
                            <p class="text" style="white-space: pre-wrap" v-html="newsDetail.noticeContent.content"></p>
                            <!-- <p class="text" v-html="newsDetail.noticeContent.content">{{newsDetail.noticeContent.content}}</p> -->

                            <!-- <img src="../../assets/images/aboutUsContent2Bg.png" alt="">
                            <p class="text"> 金华市科技局党组成员、副局长胡卫国，浙江省科技信息院副院长胡芒谷，上海万方数据有限公司总经理王亚楠分别为会议致辞，祝贺会议顺利召开。王总感谢各专家、领导及行业伙伴的大力支持，未来万方数据将继续通过万方选题、专利分析、灵析、科慧、科研诚信服务平台、产业科技创新服务平台等知识服务助力科研工作者的信息利用能力提升，促进科研创新发展，支撑科研决策参考咨询，为推动科技信息行业的发展贡献更多企业力量。
                                <br>今后，万方数据将继续为大家做好技术支撑和知识服务工作，继续助力科研机构的现代化建设和高质量发展，与大家一起共同推动科技信息事业的繁荣发展，踏着大步向着高水平科技自立自强迈进。</p> -->
                            <!-- <div class="from">
                                数据来源：<img src="../../assets/images/wanfangLogo.png" alt=""><span>万方数据知识服务平台</span>
                            </div> -->
                        </div>
                    </div>
                </a-col>
                <!-- <a-col class="contentR" :md="24" :lg="6" :xl="6">
                    <div class="contentRBox">
                        <div class="title">科研服务</div>
                        <ul>
                            <li><img src="../../assets/images/scientificIcon01.png" alt=""><span>检测预警服务</span></li>
                            <li><img src="../../assets/images/scientificIcon03.png" alt=""><span>诚信学堂学习</span></li>
                            <li><img src="../../assets/images/scientificIcon02.png" alt=""><span>学术诚信档案</span></li>
                            <li><img src="../../assets/images/scientificIcon04.png" alt=""><span>万方检测</span></li>
                        </ul>
                    </div>
                </a-col> -->
            </a-row>
        </div>
    </div>
  </div>
</template>

<script>
import { queryContentDetail } from '@/api/EvidenceService'
export default {
  components: {},
  data () {
    return {
        newsDetail: {
            noticeContent:{
                content: ''
            }
        },
    }
  },
  created () {
    this.init();
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },

    init(){
        let id = this.$route.query.id;
        queryContentDetail(id).then(({data}) => {
            // console.log(data);
            data.createTimeText= this.dayjs(data.createTime*1000).format('YYYY/MM/DD HH:mm:ss ');
            this.newsDetail = data;
        })
    }
  },
}
</script>

<style lang="scss" scoped>
    .banner{
        background: url(../../assets/images/scientificListBg.png)no-repeat;
        background-size: cover;
        height: 240px;
        p{
            font-size: 32px;
            font-family: 'SourceHanSansCN, SourceHanSansCN';
            font-weight: 400;
            color: #FFFFFF;
            line-height: 240px;
        }
    }
    .content{
        margin: 30px 0;
        .contentL{
            .contentLBox{
                background: #FFFFFF;
                box-shadow: 0px 2px 7px 0px rgba(42,45,77,0.12);
                border-radius: 8px;
                padding: 30px 40px 60px 40px;
                .contentText{
                    .title{
                        font-size: 24px;
                        font-family: 'PingFangSC, PingFang SC';
                        font-weight: 600;
                        color: rgba(0,6,22,0.85);
                        line-height: 36px;
                        text-align: center;
                        margin-top: 30px;
                    }
                    .date{
                        display: block;
                        font-size: 14px;
                        font-family: 'SourceHanSansCN, SourceHanSansCN';
                        font-weight: 400;
                        color: rgba(0,9,22,0.65);
                        line-height: 30px;
                        text-align: center;
                    }
                    .text{
                        font-size: 14px;
                        font-family: 'SourceHanSansCN, SourceHanSansCN';
                        font-weight: 400;
                        color: #596178;
                        line-height: 28px;
                        margin: 15px 0;
                    }
                    img{
                        max-width: 80%;
                        display: block;
                        margin: 0 auto;
                    }
                    .from{
                        display: flex;
                        align-items: center;
                        justify-content: right;
                        color: #999999;
                        margin-top: 30px;
                        img{
                            width: 18px;
                            height: 18px;
                            margin: 0;
                            margin-right: 6px;
                        }
                        span{
                            color: #333333;
                        }
                    }
                }
            }
        }
        .contentR{
            .contentRBox{
                padding-left: 20px;
                .title{
                    font-size: 18px;
                    font-family: 'PingFangSC, PingFang SC';
                    font-weight: 500;
                    color: #2A2D4D;
                    line-height: 20px;
                    margin-bottom: 15px;
                }
                ul{
                    li{
                        height: 100px;
                        background: linear-gradient(270deg, #F0F4FF 0%, #FFFFFF 100%);
                        box-shadow: 0px 2px 10px 0px rgba(48,83,147,0.05);
                        border-radius: 8px;
                        border: 1px solid #E9EBEE;
                        display: flex;
                        align-items: center;
                        padding: 0 40px;
                        margin-bottom: 15px;
                        img{
                            width: 60px;
                            height: 60px;
                            margin-right: 20px;
                        }
                        span{
                            font-size: 18px;
                            font-family: 'SourceHanSansCN, SourceHanSansCN';
                            font-weight: 500;
                            color: #333333;
                            line-height: 30px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

    // 适配移动端
@media screen and (max-width: 991px){
    .contentRBox{
        padding-left: 0!important;
        margin-top: 30px;
    }
}
</style>
